import * as React from "react";
import Head from "../components/Head";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Instagram from "../components/InstagramEmbeded";
import Recruit from "../components/Recruit";
import News from "../components/News";
import FirstView from "../components/FirstView";
import TopService from "../components/TopService";
import TextSection from "../components/TextSection";
import TopPageSecondView from "../components/TopPageSecondView";
import Store from "../components/Store";
import { Box } from "@mui/material";
import AOS from "aos";

const IndexPage = () => {
  if (typeof document !== `undefined`) {
    console.log("find document");
    AOS.init({ duration: 800, easing: "ease-in-sine", delay: 300 });
  }
  return (
    <>
      <Head imagePath="https://forustyle-985bb.web.app/ogp-top.jpg" />
      <Header isTop />
      <FirstView />
      <div data-aos="fade-in">
        <TextSection bgcolor="#A7ADAB" />
      </div>
      <div data-aos="fade-in">
        <TopPageSecondView />
      </div>
      <div id="store"></div>
      <div data-aos="fade-in">
        <Store />
      </div>
      <div data-aos="fade-in">
        <TopService />
      </div>
      <div data-aos="fade-in">
        <News />
      </div>
      <div data-aos="fade-in">
        <Recruit />
      </div>
      <Footer />
    </>
  );
};

export default IndexPage;
